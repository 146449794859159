<template>
  <section class="section p-5">
    <div>
      <app-logo class="my-5"/>
      <h5 class="title is-5 pt-5 mb-1">{{$t('account.login')}}</h5>
      <div class="fill-strech mt-5">
        <b-field>
          <b-input
            :placeholder="$t('account.email')"
            type="email"
            autocomplete="email"
            v-model="email"
            expanded
            :autofocus="true"/>
        </b-field>
        <b-field v-if="needsPassword" :message="$t('account.userRequiresPassword')">
          <b-input type="password" minlength="1" v-model="password" password-reveal expanded autocomplete="current-password" />
        </b-field>
        <b-button type="is-primary" expanded class="mt-5 mb-3" :disabled="loginButtonDisabled" @click="Login()">
          <d-icon pack="fas" icon="key"/>&nbsp; {{$t('account.login')}}
        </b-button>
      </div>
      <div class="m-3 has-text-centered">
        {{$t('account.dontHaveAccount')}}
        <a @click="GoToRegister()" class="goLink has-text-black-bis has-text-weight-bold">
          {{$t('account.signUp')}}</a>
      </div>
    </div>
  </section>
</template>

<script>
import adminState from "@/store/admin";

const ACCOUNTSTATE = Object.freeze({
  inactive: 0,
  login: 1,
  register: 2,
  verify: 3,
});

export default {
  components: {},
  props: {},
  data() {
    return {
      email: "",
      needsPassword: false,
      password: "",
      loginButtonDisabled: true,
    };
  },
  computed: {
    ShowMe() {
      return this.$store.state.user.accountState == ACCOUNTSTATE.login;
    },
  },
  methods: {
    async Login() {
      this.$store.commit("StartLoading");
      await this.$store.dispatch("user/GetPlayerRole", this.email);
      if (
        !this.needsPassword &&
        this.$store.getters["user/hasAdminAuthority"]
      ) {
        this.needsPassword = true;
        this.$store.commit("StopLoading");
        return;
      }
      // console.log(this.email, this.password);
      this.$store
        .dispatch("user/AuthPlayer", {
          email: this.email,
          password: this.password,
        })
        .then(async () => {
          this.$store.commit("user/SetEmail", this.email);
          this.$store.commit("StopLoading");
          // 👑 ADMIN MODE Store layer
          if (!this.$store.hasModule("admin")) {
            this.$store.registerModule("admin", adminState); // dynamic vuex module
            this.$store.commit("admin/GetRawData");
          }
          await this.$store.dispatch("user/GetAchievements");
          this.$store.dispatch("user/NeedsToRegister")
          this.$socket.client.open();
        })
        .catch((err) => {
          console.log("login err", err);
          this.$buefy.notification.open({
            message: this.$t('notifications.errorLogin'),
            type: "is-danger",
          });
          this.$store.commit("StopLoading");
          this.$sfxPlay('negativenotification')
        });
    },
    GoToRegister() {
      this.$router.push("/signup");
    },
  },
  watch:{
    email(){
      let isValidEmailRegex = this.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      if(isValidEmailRegex){
        this.loginButtonDisabled = false;
      }else{
        this.loginButtonDisabled = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
  .section > div {
    display: flex;
    flex-flow: column;
    align-items: stretch;
  }
  
  .fill-strech {
    flex: 1 1 auto;
  }
  .goLink {
    display: inline-block;
  }
  </style>